import React from "react"
import Layout from "../../../components/Layout"
import SEO from "../../../components/Seo"
import "../work-pages.scss"
import StyledCyborgGirlBackgroundSection from "../../../components/backgroundImages/CyborgGirlBackgroundSection"
import { useStaticQuery, graphql } from "gatsby"
import { FluidImage } from "../../../components/images/FluidImage"

const SekkeiWork = () => {
  const query = useStaticQuery(graphql`
    query {
      logoDesigns: file(relativePath: { eq: "logo-designs.png" }) {
        childImageSharp {
          fluid(maxWidth: 1661) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cyberpunkCity: file(
        relativePath: { eq: "cyberpunk-city-filler-image.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1661) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO title="Sekkei Work" />
      <StyledCyborgGirlBackgroundSection
        id="imageSpacer"
        style={{ position: "unset", backgroundAttachment: "fixed" }}
      />

      <div id="infoArea">
        <ul id="groupContainer">
          <li id="leftColumn">SEKKEI</li>
          {/* middle column is just a white background div :P */}
          <li id="middleColumn"></li>
          <li id="rightColumn">
            SEKKEI BRANDING
            <br />
            <div id="subText">Web Design R&D</div>
          </li>
        </ul>
      </div>

      {/* 
        this is so that I can change the background color of 
        all the images w/out changing the background of the body 
      */}
      <div id="pictureContainer">
        {/* area for the pictures and stuff */}
        <section className="pictureSection">
          <FluidImage data={query.logoDesigns} alt="Sekkei Logo Design" />
        </section>
        <section className="pictureSection">
          <FluidImage data={query.cyberpunkCity} alt="Cyberpunk City Filler" />
        </section>
        <section className="pictureSection">
          <FluidImage data={query.cyberpunkCity} alt="Cyberpunk City Filler" />
        </section>
      </div>
    </Layout>
  )
}

export default SekkeiWork
